import React from 'react';
import { BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';

import Header from './component/Comman/Header';
import Lists from './component/Running/Lists';
import Grids from './component/Running/Grids';

const Routes = (props) => {

    return (
        <BrowserRouter>
            <Header />

            <Switch>
                <Route path="/running" component={Lists}/>
                <Route path="/" component={Grids} exact/>
                
            </Switch>

        </BrowserRouter>
    )
};

export default Routes;
import React, { Component } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@material-ui/lab/Skeleton';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';


import axios from "axios";

import {RUNNING_PAGE_API_URL, LIVE_DOMAIN, SOCKET_URL, WHATSAPP_SHARE_API_URL} from '../Comman/Constant';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

class Lists extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            orderList: [],
            soldOutArray : {},
            cancelOrder: false,
            numberOfTotalOrder : 0
        }
    }

    componentDidMount(){

        this.runningSocketConnection = new WebSocket(SOCKET_URL);
        this.runningSocketConnection.onmessage = this.onMessage.bind(this);

        const requestParam = {
            language_id: 1,
        }
        this.fetchData(requestParam);
    }

    fetchData = async (requestParam) => {

        this.setState({
            isLoading: true,                
        });

        var that = this;

        await axios.post(RUNNING_PAGE_API_URL, requestParam,            
            { 
                headers: {
                'Authorization': "5ece4797eaf5e5ece4797eaf5e" 
                }
            })
            .then((response) => {  
                
                this.setState({
                    orderList: response.data.runningOrderListData ? response.data.runningOrderListData : [],
                    soldOutArray: response.data.is_order_sold_out ? response.data.is_order_sold_out : [],
                    isLoading: false,
                    numberOfTotalOrder: response.data.numberOfTotalOrder ? response.data.numberOfTotalOrder : 0,
                })
              
            }).catch(function (error) {
               
                if(error.response.status==401){
                     that.props.history.push('/');
                }
        });
    }

    onMessage(event) {
        const socketData = JSON.parse(event.data);
       
        const requestParam = {
            language_id: 1,           
        }
        // ORDER PLACE
        if (socketData.requesttype == "checkout" && socketData.action == 'order_place') {                  
            var sound = document.getElementById("notification_alert");
            sound.play();
            this.fetchData(requestParam);
        }
        // ORDER PAID
        if (socketData.requesttype == "orderstatus" && socketData.status == 'paid') {                  
            document.getElementById("paid_status_"+ socketData.orderid).innerText ="PAID";
            document.getElementById("paid_status_"+ socketData.orderid).style.color="#0a800e";
        }
        // ORDER CANCELLED
        if (socketData.requesttype == "orderstatus" && socketData.customer_cancel == 'yes') {     
            var sound = document.getElementById("cancel_notification_alert");
            sound.play();                             
            this.setState({
                cancelOrder: true,
                cancelOrderId: socketData.order_id
            });
            this.fetchData(requestParam);
        }

    }

    cancelOptionModel = (order_id) => {
        this.setState({
            cancelModelOpen: true,   
            cancelOrderId: order_id         
        });
    }

    operationalModelOpen = (order_id) => {
        this.setState({
            operationalModelOpen: true,   
            operationalOrderId: order_id         
        });
    }

    cancelOptionModelClose = () => {
        this.setState({
            cancelModelOpen: false,            
        });
    }

    handleCancelOptionChange = (event) => {
        if(event.target.value == 'Other'){
            this.setState({
                cancelOther: true,            
            });
        } else {
            this.setState({
                cancelOther: false,            
            });
        }    
    }

    cancelModelClose = () =>{
        this.setState({
            cancelOrder: false,            
        });
    }

    whatsAppShare = (order_id) => {
        const requestParam = {
            language_id: 1,
            order_id: order_id
        }
        axios.post(WHATSAPP_SHARE_API_URL, requestParam,            
        { 
            headers: {
            'Authorization': "5ece4797eaf5e5ece4797eaf5e" 
            }
        });        
        document.getElementById("wh_share_"+ order_id).style.backgroundColor="#303f9f"; 
    }

    render(){

        const orderLists = this.state.orderList;
        const soldOutArray = this.state.soldOutArray;
        const numberOfTotalOrder = this.state.numberOfTotalOrder;
    
        return(
           
            <TableContainer component={Paper}>
                
                {
                    this.state.isLoading == false &&
                    <div style={{padding: "20px"}}>Total Orders: {numberOfTotalOrder}</div>
                }
                
                {/* SOUND */}
                <audio id="notification_alert" allow="autoplay" src={LIVE_DOMAIN + "assets/backend/music/web_notification.mp3"}
                type="audio/mp3"></audio>
                <audio id="cancel_notification_alert" allow="autoplay" src={LIVE_DOMAIN + "assets/backend/music/cancel_notification.mp3"}
                type="audio/mp3"></audio>

                {/* CANCEL ALERT */}
                <Dialog
                open={this.state.cancelOrder}            
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Order cancelled: "+ this.state.cancelOrderId}</DialogTitle>
               
                <DialogActions>
                <Button onClick={this.cancelModelClose} color="primary">
                    OKAY
                </Button>                
                </DialogActions>
                </Dialog>

                <Dialog
                open={this.state.cancelModelOpen}                
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Ready to cancel?"}</DialogTitle>
                    <form action={LIVE_DOMAIN + "backend/orders/cancelOrder/" + this.state.cancelOrderId} method='post'>
                    <DialogContent>  
                        
                        <FormControl component="fieldset">                            
                            <RadioGroup aria-label="cancel_reasons" name="cancel_reasons" onChange={this.handleCancelOptionChange}>
                                <FormControlLabel value="Item sold out" control={<Radio color="primary" />} label="Item sold out" />
                                <FormControlLabel value="Customer request" control={<Radio color="primary" />} label="Customer request" />
                                <FormControlLabel value="No payment" control={<Radio color="primary"/>} label="No payment" />
                                <FormControlLabel value="Issue with the order" control={<Radio color="primary"/>} label="Issue with the order" />
                                <FormControlLabel value="Drivers shortage" control={<Radio color="primary"/>} label="Drivers shortage" />
                                <FormControlLabel value="Other" control={<Radio color="primary"/>} label="Other" />
                            </RadioGroup>
                            {
                                this.state.cancelOther && <TextField id="standard-basic" label="Other Reasons" name="cancel_reasons_text"/>
                            }
                            <input type="hidden" name="order_id" value={this.state.cancelOrderId}/>
                        </FormControl>
                                                                    
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.cancelOptionModelClose} color="primary">
                        Close
                    </Button>
                    <Button color="primary" autoFocus type="submit">
                        Cancel Order
                    </Button>
                    </DialogActions>
                    </form>  
                </Dialog>

                <Dialog
                open={this.state.operationalModelOpen}                
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Select Reason for operational"}</DialogTitle>
                    <form action={LIVE_DOMAIN + "backend/orders/operational_order_action/"} method='post'>
                    <DialogContent>  
                        
                        <FormControl component="fieldset">      
                         <InputLabel id="demo-mutiple-checkbox-label">Select Reason for operational</InputLabel>                      
                             <Select
                             autoWidth 
                             displayEmpty
                             name="operational_reason"
                             inputProps={{ 'aria-label': 'Without label' }}
                             >                               
                                <MenuItem value="١-يوجد صنف ناقصmissing item">١-يوجد صنف ناقصmissing item </MenuItem>
                                <MenuItem value="٢-الصنف غير صحيحWrong item">٢-الصنف غير صحيحWrong item</MenuItem>
                                <MenuItem value="٣-لا يوجد كتابة على الكيك No writing on cake">٣-لا يوجد كتابة على الكيك No writing on cake</MenuItem>
                                <MenuItem value="٤-الاصناف غير متوفره Items sold out">٤-الاصناف غير متوفره Items sold out</MenuItem>
                                <MenuItem value="٥-تعديل على الطلب Amend order ">٥-تعديل على الطلب Amend order </MenuItem>
                                <MenuItem value="٦-الصنف متضرر Damaged item">٦-الصنف متضرر Damaged item</MenuItem>
                                <MenuItem value="٧-العميل يريد اضافة صنف Customer want add item">٧-العميل يريد اضافة صنف Customer want add item</MenuItem>
                                <MenuItem value="٨-لم يتم الالتزام بملاحظة العميلNo follow customer comments">٨-لم يتم الالتزام بملاحظة العميلNo follow customer comments</MenuItem>
                                <MenuItem value="٩-العميل يريد الغاء الطلبCustomer want to cancel order">٩-العميل يريد الغاء الطلبCustomer want to cancel order</MenuItem>
                                <MenuItem value="١٠-خطأ في موقع المحل Wrong shop location ">١٠-خطأ في موقع المحل Wrong shop location </MenuItem>
                                <MenuItem value="١١-خطأ في موقع العميل Wrong customer location ">١١-خطأ في موقع العميل Wrong customer location</MenuItem>
                                <MenuItem value="١٢-المحل مغلق Shop closed ">١٢-المحل مغلق Shop closed</MenuItem>
                                <MenuItem value="١٣-المحل رافض تسليم الطلب Shop refused to give items">١٣-المحل رافض تسليم الطلب Shop refused to give items</MenuItem>
                                <MenuItem value="١٤-ايقاف المحل Block shop">١٤-ايقاف المحل Block shop</MenuItem>
                                <MenuItem value="١٥-ايقاف الصنف Block item">١٥-ايقاف الصنف Block item</MenuItem>
                                <MenuItem value="Customer don't pay لم يقم العميل بالدفع">Customer don't pay لم يقم العميل بالدفع</MenuItem>
                            </Select>  
                            <TextField id="standard-basic" label="Add Comment" name="admin_operational_comment"/>                          
                            <input type="hidden" name="order_id" value={this.state.operationalOrderId}/>
                        </FormControl>
                                                                    
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.cancelOptionModelClose} color="primary">
                        Close
                    </Button>
                    <Button color="primary" autoFocus type="submit">
                        Submit
                    </Button>
                    </DialogActions>
                    </form>  
                </Dialog>
                {
                    this.state.isLoading == true ?
                    <>
                    <Skeleton height={100} /> 
                    <Skeleton height={100} />
                    <Skeleton height={100} />
                    <Skeleton height={100} />
                    <Skeleton height={100} />
                    <Skeleton height={100} />
                    <Skeleton height={100} />
                    <Skeleton height={100} />
                    <Skeleton height={100} />
                    <Skeleton height={100} />
                    <Skeleton height={100} />
                    </>
                    :
                    <Table aria-label="simple table" style={{ width: "auto", tableLayout: "auto" }}>
                        <TableHead>
                        <TableRow>
                            <StyledTableCell>Id</StyledTableCell>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Cus Phone</StyledTableCell>
                            <StyledTableCell component="th" scope="row">Customer Location</StyledTableCell>
                            <StyledTableCell>Payment</StyledTableCell>
                            <StyledTableCell>Plate?</StyledTableCell>
                            <StyledTableCell>Shop</StyledTableCell>
                            <StyledTableCell>Confirmed</StyledTableCell>
                            <StyledTableCell>Shop Phone</StyledTableCell>
                            <StyledTableCell>Del Cost</StyledTableCell>
                            <StyledTableCell>Driver</StyledTableCell>
                            <StyledTableCell>Timer</StyledTableCell>
                            <StyledTableCell>Product Note</StyledTableCell>
                            <StyledTableCell>Request</StyledTableCell>
                            <StyledTableCell>Schedule?</StyledTableCell>                    
                            <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {orderLists.map((row) => (
                            <TableRow id={"list_row_"+ row.id} key={row.id}>
                            <TableCell id={"list_" + row.id} style={{backgroundColor:row.orderColor}}>
                                <a style={{color: '#fff'}} target="_blank" href={ LIVE_DOMAIN + "backend/orders/detail/" + row.id}>{row.id}</a>
                            </TableCell>
                            <TableCell>{row.orderDate}</TableCell>
                            <TableCell>SAR {row.orderAmount}</TableCell>
                            <TableCell><a href={"tel: " + row.customerMobile}>{row.customerMobile}</a></TableCell>
                            <TableCell component="th" scope="row" style={{fontSize: '10px'}}>{row.location}</TableCell>
                            <TableCell>
                                {row.product_payment_type + " - "} 
                                { 
                                    row.payment_status == 'paid' ? 
                                    <strong><span id={"paid_status_" + row.id} style={{color: '#0a800e'}}>PAID</span></strong> : 
                                    <strong><span id={"paid_status_" + row.id} style={{color: '#ff0000'}}>UNPAID</span></strong> 
                                }
                            </TableCell>
                            <TableCell style={{color: '#ff0000'}}>{row.is_plate == 'yes' ? "YES" : "NO"}</TableCell>
                            <TableCell style={{fontSize: '10px'}}>
                                {row.branchName}
                                <br></br>
                                {row.shiftOneText} 
                                <br></br>
                                {row.shiftTwoText}
                            </TableCell>
                            <TableCell style={{fontSize: '10px'}}>
                                {row.confirmed_by}
                                <br></br>
                                {row.confirmed_time}
                                <span id={"order_update_span_" + row.id} style={{color: '#ff0000'}}>
                                    {
                                        soldOutArray[row.id] == 'yes' ?
                                        <span>
                                            <br></br>
                                            Order amended
                                        </span> : null
                                    }
                                </span>
                            </TableCell>
                            <TableCell><a href={"tel: " + row.shop_phone}>{row.shop_phone}</a></TableCell>
                            <TableCell>SAR {row.deliveryActualCharge}</TableCell>
                            <TableCell>
                                {row.deliveryName && row.deliveryName}
                                <br></br>
                                {
                                    row.deliveryNumber &&
                                    <a href={"tel: " + row.deliveryNumber}>{row.deliveryNumber}</a>
                                }  
                                <br></br>
                                {
                                    row.has_order
                                }                          
                            </TableCell>
                            <TableCell style={{color: '#ff0000'}} id={"step_order_id_" + row.id} className="stepInterval" data-step_order_id={row.id} data-order_step_date={row.orderStepDate}>-</TableCell>
                            <TableCell>{row.product_note}</TableCell>
                            <TableCell style={{color: '#ff0000'}}>{row.special_request_note}</TableCell>
                            <TableCell>{row.scheduleOrder}</TableCell>                    
                            <TableCell>
                                <ButtonGroup variant="contained" color="primary" size="small" aria-label="contained small primary button group">
                                    {row.status == 'pending' ? 
                                        <Button className={"confirm_order_" + row.id}>
                                            <Link style={{ textDecoration: 'inherit'}} color="inherit" href={LIVE_DOMAIN + "backend/orders/confirmOrder/" + row.id + "/" + row.customerId}>
                                                Confirm
                                            </Link>
                                            
                                        </Button>
                                    :                                    
                                        row.payment_status == 'unpaid' &&
                                        <Button>
                                            <Link style={{ textDecoration: 'inherit'}} color="inherit" href={LIVE_DOMAIN + "backend/orders/paymentRequest/" + row.id + "/" + row.customerId}>
                                                Pay Request
                                            </Link>                                    
                                        </Button>                                                
                                    }    
                                    <Button>
                                        <Link target="_blank" style={{ textDecoration: 'inherit'}} color="inherit" href={LIVE_DOMAIN + "backend/orders/assign/" + row.id + "/?lat=" + row.branch_shop_latitude + "&lng=" + + row.branch_shop_longitude}>
                                            Assign
                                        </Link>                                    
                                    </Button>    
                                    <Button>
                                        <Link target="_blank" style={{ textDecoration: 'inherit'}} color="inherit" href={LIVE_DOMAIN + "admin/report/edit_order/" + row.id }>
                                            Edit
                                        </Link>                                    
                                    </Button>                                                 
                                    
                                    <Button onClick={ e => this.cancelOptionModel(row.id)}>Cancel</Button>
                                    <Button onClick={ e => this.operationalModelOpen(row.id)}>Operational</Button>
                                    <Button>
                                        <Link target="_blank" style={{ textDecoration: 'inherit'}} color="inherit" href={LIVE_DOMAIN + "admin/chat_history/chat_detail/" + row.device_id + "?mobile=" + row.customerMobile + "&order_no=" + row.id}>
                                            Chat
                                        </Link>                                    
                                    </Button>  
                                    <Button id={"wh_share_" + row.id} color={row.share_time == 1 ? "primary" : "secondary"} onClick={ e => this.whatsAppShare(row.id)}> 
                                        <Link target="_blank" style={{ textDecoration: 'inherit'}} color="inherit" href={"whatsapp://send?text=" + row.share_msg}>
                                            Share
                                        </Link> 
                                    </Button>                           
                                </ButtonGroup>
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                }
                
                </TableContainer>
        );
    }
}

export default Lists;
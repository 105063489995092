import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import axios from "axios";

import { RUNNING_PAGE_API_URL, LIVE_DOMAIN, SOCKET_URL, WHATSAPP_SHARE_API_URL } from '../Comman/Constant';
import { Helmet } from 'react-helmet';

const styles = theme => ({
    root: {
        Width: "275px",
        Height: "300px"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    avatar: {
        backgroundColor: red[500],
    },
});

class Grids extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            orderList: [],
            branchList: [],
            soldOutArray: {},
            cancelOrder: false,
            numberOfTotalOrder: 0,
            filterList: [],
            activeDriverList: []
        }
        this.selectFilterCallback = this.selectFilterCallback.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {

        this.runningSocketConnection = new WebSocket(SOCKET_URL);
        this.runningSocketConnection.onmessage = this.onMessage.bind(this);

        const requestParam = {
            language_id: 1,
        }
        this.fetchData(requestParam);

        var intervalId = setInterval(this.timer, 1000);
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervalId: intervalId });

    }

    /*ORDER PROCESS TIMER IN RUNNING PAGE*/
    timer = () => {
        var list = document.getElementsByClassName("stepInterval");
        for (var i = 0; i < list.length; ++i) {
            var item = list[i];
            var order_id = item.getAttribute('data-step_order_id');
            var stepInterval = item.getAttribute('data-order_step_date');
            var orderStepStrToTime = new Date(stepInterval).getTime();
            if (orderStepStrToTime) {
                this.orderStepInterval(order_id, orderStepStrToTime);
            }
        }

        var scheduleList = document.getElementsByClassName("schedule_order");
        for (var i = 0; i < scheduleList.length; ++i) {
            var item = scheduleList[i];
            var order_id = item.getAttribute('data-order_id');
            var scheduleDate = item.getAttribute('data-schedule_date');
            var scheduleStrToTime = new Date(scheduleDate).getTime();
            if (scheduleStrToTime) {
                this.scheduleSecondPassed(order_id, scheduleStrToTime);
            }
        }
    }
    scheduleSecondPassed = (scheduleOrderId, scheduleDate) => {
        // Get today's date and time
        var now = new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" });
        now = new Date(now).getTime();
        var distance = scheduleDate - now;

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (days == 0 && hours == 1 && minutes == 29 && seconds == 59) {
            var sound = document.getElementById("notification_alert");
            sound.play();
            const requestParam = {
                language_id: 1,
            }
            this.fetchData(requestParam);
        }
        if (days == 0 && hours == 0) { }
        if (distance < 0) { }
    }
    orderStepInterval = (order_id, orderStepStrToTime) => {
        var now = new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" });
        now = new Date(now).getTime();

        var milisec = now - orderStepStrToTime;

        var days = Math.floor(milisec / (1000 * 60 * 60 * 24));
        var hours = Math.floor((milisec % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((milisec % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((milisec % (1000 * 60)) / 1000);

        document.getElementById("step_order_id_" + order_id).innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
    }

    fetchData = async (requestParam) => {

        this.setState({
            isLoading: true,
        });

        requestParam.start_date = requestParam.filter_start_date ? requestParam.filter_start_date : this.state.filter_start_date;
        requestParam.end_date = requestParam.filter_end_date ? requestParam.filter_end_date : this.state.filter_end_date;

        var that = this;
        await axios.post(RUNNING_PAGE_API_URL, requestParam,
            {
                headers: {
                    'Authorization': "5ece4797eaf5e5ece4797eaf5e"
                }
            })
            .then((response) => {

                this.setState({
                    orderList: response.data.runningOrderListData ? response.data.runningOrderListData : [],
                    activeDriverList: response.data.activeDriverData ? response.data.activeDriverData : [],
                    soldOutArray: response.data.is_order_sold_out ? response.data.is_order_sold_out : [],
                    branchList: response.data.branchList ? response.data.branchList : [],
                    filterList: response.data.filterList ? response.data.filterList : [],
                    isLoading: false,
                    numberOfTotalOrder: response.data.numberOfTotalOrder ? response.data.numberOfTotalOrder : 0,
                })

            }).catch(function (error) {
                console.log(error)
                if (error.response.status == 401) {
                    that.props.history.push('/');
                }
            });
    }

    onMessage(event) {
        const socketData = JSON.parse(event.data);

        const requestParam = {
            language_id: 1,
            shop_id: this.state.selectedBranchData ? this.state.selectedBranchData.id : '',
            filter_by: this.state.selectedFilterData ? this.state.selectedFilterData.id : ''
        }

        // ORDER PLACE
        if (socketData.requesttype == "checkout" && socketData.action == 'order_place') {
            this.fetchData(requestParam);
            var sound = document.getElementById("notification_alert");
            sound.play();
        }
        // ORDER PAID
        if (socketData.requesttype == "orderstatus" && socketData.status == 'paid') {
            //document.getElementById("paid_status_"+ socketData.orderid).innerText ="PAID";
            //document.getElementById("paid_status_"+ socketData.orderid).style.color="#0a800e";
            this.fetchData(requestParam);
        }

        /*SCHEDULE ORDER ONE HOUR LEFT SOUND*/
        if (socketData.requesttype == "sound" && socketData.status == 'schedule_order') {
            this.fetchData(requestParam);
            var sound = document.getElementById("notification_alert");
            sound.play();
        }

        // ORDER CANCELLED
        if (socketData.requesttype == "orderstatus" && socketData.customer_cancel == 'yes') {
            this.setState({
                cancelOrder: true,
                cancelOrderId: socketData.order_id
            });
            this.fetchData(requestParam);
            var sound = document.getElementById("cancel_notification_alert");
            sound.play();
        }

        if (socketData.requesttype == "orderstatus" && socketData.status == 'shop_confirmed') {
            this.fetchData(requestParam);
        }

        if (socketData.requesttype == "orderstatus" && socketData.status == 'delivery_confirmed') {
            this.fetchData(requestParam);
        }

        if (socketData.requesttype == "orderstatus" && socketData.item_collected == 'yes') {
            this.fetchData(requestParam);
        }

        if (socketData.requesttype == "orderstatus" && socketData.product_ds_or_not == 'yes') {
            this.fetchData(requestParam);
        }

    }

    OrderActionButtonShow = (event, order_id, customerId, status, payment_status, share_time, branch_shop_latitude, branch_shop_longitude, device_id, customerMobile, share_msg) => {
        this.setState({
            show: event.currentTarget,
            selectedOrderId: order_id,
            selectedCustomerId: customerId,
            selectedOrderStatus: status,
            selectedOrderPaymentStatus: payment_status,
            selectedOrderShare_time: share_time,
            branch_shop_latitude: branch_shop_latitude,
            branch_shop_longitude: branch_shop_longitude,
            selectedDeviceId: device_id,
            selectedCustomerMobile: customerMobile,
            share_msg: share_msg
        });
    }

    OrderActionButtonHide = () => {
        this.setState({
            show: null,
        });
    }

    cancelOptionModel = (order_id) => {
        this.setState({
            cancelModelOpen: true,
            cancelOrderId: order_id
        });
    }

    operationalModelOpen = (order_id) => {
        this.setState({
            operationalModelOpen: true,
            operationalOrderId: order_id
        });
    }

    cancelOptionModelClose = () => {
        this.setState({
            cancelModelOpen: false,
        });
    }

    cancelSubmit = (order_id) => {
        const socketData = {
            order_id: order_id,
            customer_cancel: 'yes',
            requesttype: "orderstatus",
        }
        this.runningSocketConnection.send(JSON.stringify(socketData));
        document.getElementById('cancelSubmitForm').submit();
    }

    orderConfirmSubmit = (order_id, customer_id) => {
        const socketData = {
            order_id: order_id,
            item_collected: 'no',
            delivery_status: 'no',
            customer_cancel: 'no',
            product_ds_or_not: 'no',
            status: 'shop_confirmed',
            device_type: "web",
            requesttype: "orderstatus",
        }
        this.runningSocketConnection.send(JSON.stringify(socketData));
        document.getElementById('orderConfirmSubmit').submit();
    }

    orderPaySubmit = (order_id, customer_id) => {
        const socketData = {
            order_id: order_id,
            item_available: 'yes',
            status: 'shop_confirmed',
            device_type: "web",
            requesttype: "orderstatus",
        }
        this.runningSocketConnection.send(JSON.stringify(socketData));
        document.getElementById('orderPaySubmit').submit();
    }

    handleCancelOptionChange = (event) => {
        if (event.target.value == 'Other') {
            this.setState({
                cancelOther: true,
            });
        } else {
            this.setState({
                cancelOther: false,
            });
        }
    }

    cancelModelClose = () => {
        this.setState({
            cancelOrder: false,
        });
    }

    whatsAppShare = (order_id) => {
        const requestParam = {
            language_id: 1,
            order_id: order_id
        }
        axios.post(WHATSAPP_SHARE_API_URL, requestParam,
            {
                headers: {
                    'Authorization': "5ece4797eaf5e5ece4797eaf5e"
                }
            });
        document.getElementById("wh_share_" + order_id).style.backgroundColor = "#303f9f";
        document.getElementById("shared_id_" + order_id).textContent = 'SHARED';

    }

    handleStartDateChange = (startDate) => {

        var now = new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" });
        var fullYear = new Date(startDate).getFullYear();
        var getMonth = new Date(startDate).getMonth() < 9 ? "0" + (new Date(startDate).getMonth() + 1) : (new Date(startDate).getMonth() + 1);
        var getDate = new Date(startDate).getDate() < 10 ? "0" + new Date(startDate).getDate() : new Date(startDate).getDate();

        var fullDate = fullYear + "-" + getMonth + "-" + getDate;

        this.setState({
            selectedStartDate: startDate,
            filter_start_date: fullDate
        });
        const requestParam = {
            language_id: 1,
            filter_start_date: fullDate
        }
        this.fetchData(requestParam);
    }

    handleEndDateChange = (endDate) => {
        var now = new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" });
        var fullYear = new Date(endDate).getFullYear();
        var getMonth = new Date(endDate).getMonth() < 9 ? "0" + (new Date(endDate).getMonth() + 1) : (new Date(endDate).getMonth() + 1);
        var getDate = new Date(endDate).getDate() < 10 ? "0" + new Date(endDate).getDate() : new Date(endDate).getDate();

        var fullDate = fullYear + "-" + getMonth + "-" + getDate;
        this.setState({
            selectedEndDate: endDate,
            filter_end_date: fullDate
        });
        const requestParam = {
            language_id: 1,
            filter_end_date: fullDate
        }
        this.fetchData(requestParam);
    }

    selectBranchCallback = (event, branchData) => {
        this.setState({
            'selectedBranchData': branchData,

        })
        const requestParam = {
            language_id: 1,
            shop_id: branchData.id,
            filter_by: this.state.selectedFilterData ? this.state.selectedFilterData.id : '',
        }
        this.fetchData(requestParam);
    }

    selectFilterCallback = (event, filterData) => {
        this.setState({
            'selectedFilterData': filterData,
        });
        const requestParam = {
            language_id: 1,
            filter_by: filterData.id,
            shop_id: this.state.selectedBranchData ? this.state.selectedBranchData.id : ""
        }
        this.fetchData(requestParam);
    }

    render() {
        const { classes } = this.props;
        const orderLists = this.state.orderList;
        const activeDriverList = this.state.activeDriverList;
        const soldOutArray = this.state.soldOutArray;
        const numberOfTotalOrder = this.state.numberOfTotalOrder;
        const branchList = this.state.branchList;
        const skeletonCount = 30;
        const branchListArray = Object.values(branchList);
        const FilterData = this.state.filterList;
        const FilterDataList = Object.values(FilterData);

        return (
            <>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {
                            this.state.isLoading == false &&
                            <div style={{ padding: "20px" }}>Total Orders: {numberOfTotalOrder}</div>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="startdate-picker-inline"
                                label="Start Date"
                                value={this.state.selectedStartDate}
                                onChange={this.handleStartDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'Start Date',
                                }}
                            />
                            <KeyboardDatePicker
                                autoOk={true}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="enddate-picker-inline"
                                label="End Date"
                                value={this.state.selectedEndDate}
                                onChange={this.handleEndDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'End Date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>

                <Autocomplete
                    id="combo-box-demo"
                    options={branchListArray}
                    getOptionLabel={(option) => option['shop_name']}
                    defaultValue={this.state.selectedBranchData}
                    onChange={(event, newSelectedValue) => {
                        newSelectedValue ?
                            this.selectBranchCallback(event, newSelectedValue)
                            :
                            this.selectBranchCallback(event, "")
                    }}
                    renderInput={(params) => <TextField {...params} label="Select Branch" variant="outlined" />}
                />
                <Autocomplete style={{ "marginTop": "5px" }}
                    id="select-filter"
                    options={FilterDataList}
                    autoSelect={true}
                    getOptionLabel={(option) => option['name']}
                    defaultValue={this.state.selectedFilterData}
                    onChange={(event, newSelectedValue) => {
                        newSelectedValue ?
                            this.selectFilterCallback(event, newSelectedValue)
                            :
                            this.selectFilterCallback(event, "")
                    }}
                    renderInput={(params) => <TextField {...params} label="Select Filter" variant="outlined" />}
                />

                <TableContainer style={{ "marginTop": "15px" }}>
                    <Table size="small" aria-label="a dense table">                    
                        <TableBody>
                             <TableRow>
                                {
                                    activeDriverList.map((driverRow) => (
                                        <>
                                            <TableCell>
                                                {driverRow.user_name}
                                            </TableCell>
                                            <TableCell>{driverRow.mobile}</TableCell>
                                        </>
                                    ))
                                } 
                            </TableRow>              
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{ "float": "left", "marginBottom": "20px", "marginTop": "5px" }}>

                    {/* SOUND */}
                    <audio id="notification_alert" allow="autoplay" src={LIVE_DOMAIN + "assets/backend/music/web_notification.mp3"}
                        type="audio/mp3"></audio>
                    <audio id="cancel_notification_alert" allow="autoplay" src={LIVE_DOMAIN + "assets/backend/music/cancel_notification.mp3"}
                        type="audio/mp3"></audio>

                    {/* CANCEL ALERT */}
                    <Dialog
                        open={this.state.cancelOrder}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Order cancelled: " + this.state.cancelOrderId}</DialogTitle>

                        <DialogActions>
                            <Button onClick={this.cancelModelClose} color="primary">
                                OKAY
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Menu
                        anchorEl={this.state.show}
                        keepMounted
                        open={Boolean(this.state.show)}
                        onClose={this.OrderActionButtonHide}
                    >
                        {
                            this.state.selectedOrderStatus == 'pending' ?
                                <MenuItem onClick={e => this.orderConfirmSubmit(this.state.selectedOrderId, this.state.selectedCustomerId)}>
                                    Confirm
                                    <form id="orderConfirmSubmit" action={LIVE_DOMAIN + "orders/confirmOrder/" + this.state.selectedOrderId + "/" + this.state.selectedCustomerId}></form>
                                </MenuItem>
                                :
                                this.state.selectedOrderPaymentStatus == 'unpaid' &&
                                <MenuItem onClick={e => this.orderPaySubmit(this.state.selectedOrderId, this.state.selectedCustomerId)}>
                                    <form id="orderPaySubmit" action={LIVE_DOMAIN + "orders/paymentRequest/" + this.state.selectedOrderId + "/" + this.state.selectedCustomerId}></form>
                                    Pay Request
                                </MenuItem>
                        }
                        <MenuItem>
                            <Link target="_blank" style={{ textDecoration: 'inherit' }} color="inherit" href={LIVE_DOMAIN + "orders/assign/" + this.state.selectedOrderId + "/?lat=" + this.state.branch_shop_latitude + "&lng=" + + this.state.branch_shop_longitude}>
                                Assign
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link target="_blank" style={{ textDecoration: 'inherit' }} color="inherit" href={LIVE_DOMAIN + "admin/report/edit_order/" + this.state.selectedOrderId}>
                                Edit
                            </Link>
                        </MenuItem>
                        <MenuItem onClick={e => this.cancelOptionModel(this.state.selectedOrderId)}>Cancel</MenuItem>
                        <MenuItem onClick={e => this.operationalModelOpen(this.state.selectedOrderId)}>Operational</MenuItem>
                        <MenuItem>
                            <Link target="_blank" style={{ textDecoration: 'inherit' }} color="inherit" href={LIVE_DOMAIN + "admin/chat_history/chat_detail/" + this.state.selectedDeviceId + "?mobile=" + this.state.selectedCustomerMobile + "&order_no=" + this.state.selectedOrderId}>
                                Chat
                            </Link>
                        </MenuItem>
                        <MenuItem id={"wh_share_" + this.state.selectedOrderId} style={{ "backgroundColor": this.state.selectedOrderShare_time == 1 ? "#0000FF" : "#fff" }} onClick={e => this.whatsAppShare(this.state.selectedOrderId)}>
                            <Link target="_blank" style={{ textDecoration: 'inherit' }} color="inherit" href={"whatsapp://send?text=" + this.state.share_msg}>
                                Share
                            </Link>
                        </MenuItem>
                    </Menu>

                    <Dialog
                        open={Boolean(this.state.cancelModelOpen)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Ready to cancel?"}</DialogTitle>
                        <form id="cancelSubmitForm" action={LIVE_DOMAIN + "orders/cancelOrder/" + this.state.cancelOrderId} method='post'>
                            <DialogContent>

                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="cancel_reasons" name="cancel_reasons" onChange={this.handleCancelOptionChange}>
                                        <FormControlLabel value="Item sold out" control={<Radio color="primary" />} label="Item sold out" />
                                        <FormControlLabel value="Customer request" control={<Radio color="primary" />} label="Customer request" />
                                        <FormControlLabel value="No payment" control={<Radio color="primary" />} label="No payment" />
                                        <FormControlLabel value="Issue with the order" control={<Radio color="primary" />} label="Issue with the order" />
                                        <FormControlLabel value="Drivers shortage" control={<Radio color="primary" />} label="Drivers shortage" />
                                        <FormControlLabel value="Other" control={<Radio color="primary" />} label="Other" />
                                    </RadioGroup>
                                    {
                                        this.state.cancelOther && <TextField id="standard-basic" label="Other Reasons" name="cancel_reasons_text" />
                                    }
                                    <input type="hidden" name="order_id" value={this.state.cancelOrderId} />
                                </FormControl>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.cancelOptionModelClose} color="primary">
                                    Close
                                </Button>
                                <Button color="primary" autoFocus type="button" onClick={e => this.cancelSubmit(this.state.cancelOrderId)}>
                                    Cancel Order
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>

                    <Dialog
                        fullWidth="true"
                        open={Boolean(this.state.operationalModelOpen)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Select Reason for operational"}</DialogTitle>
                        <form action={LIVE_DOMAIN + "orders/operational_order_action/"} method='post'>
                            <DialogContent>


                                <InputLabel id="demo-mutiple-checkbox-label">Select Reason for operational</InputLabel>
                                <Select
                                    fullWidth="true"
                                    displayEmpty
                                    name="operational_reason"
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value="١-يوجد صنف ناقصmissing item">١-يوجد صنف ناقصmissing item </MenuItem>
                                    <MenuItem value="٢-الصنف غير صحيحWrong item">٢-الصنف غير صحيحWrong item</MenuItem>
                                    <MenuItem value="٣-لا يوجد كتابة على الكيك No writing on cake">٣-لا يوجد كتابة على الكيك No writing on cake</MenuItem>
                                    <MenuItem value="٤-الاصناف غير متوفره Items sold out">٤-الاصناف غير متوفره Items sold out</MenuItem>
                                    <MenuItem value="٥-تعديل على الطلب Amend order ">٥-تعديل على الطلب Amend order </MenuItem>
                                    <MenuItem value="٦-الصنف متضرر Damaged item">٦-الصنف متضرر Damaged item</MenuItem>
                                    <MenuItem value="٧-العميل يريد اضافة صنف Customer want add item">٧-العميل يريد اضافة صنف Customer want add item</MenuItem>
                                    <MenuItem value="٨-لم يتم الالتزام بملاحظة العميلNo follow customer comments">٨-لم يتم الالتزام بملاحظة العميلNo follow customer comments</MenuItem>
                                    <MenuItem value="٩-العميل يريد الغاء الطلبCustomer want to cancel order">٩-العميل يريد الغاء الطلبCustomer want to cancel order</MenuItem>
                                    <MenuItem value="١٠-خطأ في موقع المحل Wrong shop location ">١٠-خطأ في موقع المحل Wrong shop location </MenuItem>
                                    <MenuItem value="١١-خطأ في موقع العميل Wrong customer location ">١١-خطأ في موقع العميل Wrong customer location</MenuItem>
                                    <MenuItem value="١٢-المحل مغلق Shop closed ">١٢-المحل مغلق Shop closed</MenuItem>
                                    <MenuItem value="١٣-المحل رافض تسليم الطلب Shop refused to give items">١٣-المحل رافض تسليم الطلب Shop refused to give items</MenuItem>
                                    <MenuItem value="١٤-ايقاف المحل Block shop">١٤-ايقاف المحل Block shop</MenuItem>
                                    <MenuItem value="١٥-ايقاف الصنف Block item">١٥-ايقاف الصنف Block item</MenuItem>
                                    <MenuItem value="Customer don't pay لم يقم العميل بالدفع">Customer don't pay لم يقم العميل بالدفع</MenuItem>
                                </Select>
                                <TextField id="standard-basic" label="Add Comment" name="admin_operational_comment" />
                                <input type="hidden" name="order_id" value={this.state.operationalOrderId} />


                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.cancelOptionModelClose} color="primary">
                                    Close
                                </Button>
                                <Button color="primary" autoFocus type="submit">
                                    Submit
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                    {
                        this.state.isLoading == true ?
                            <>
                                {
                                    [...Array(skeletonCount)].map((e, i) => <Skeleton style={{ "float": "left", "paddingRight": "0px", "marginRight": "10px", "marginTop": "10px", "width": "200px", "height": "200px" }} />)
                                }
                            </>
                            :

                            orderLists.map((row) => (
                                <Card id={"list_row_" + row.id} key={row.id} style={{ "float": "left", "paddingRight": "0px", "marginRight": "10px", "marginTop": "10px", "width": "288px", "border": "1px solid #001880a1" }} className={classes.root}>

                                    <CardHeader
                                        avatar={
                                            <a style={{ color: row.orderColor }} target="_blank" href={LIVE_DOMAIN + "orders/detail/" + row.id}>
                                                <Avatar aria-label="recipe" style={{ backgroundColor: row.orderColor, "width": "60px", "height": "60px" }}>
                                                    &nbsp;
                                                </Avatar>
                                            </a>
                                        }
                                        action={

                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                aria-controls="simple-menu"
                                                onClick={e => this.OrderActionButtonShow(e, row.id, row.customerId, row.status, row.payment_status, row.share_time, row.branch_shop_latitude, row.branch_shop_longitude, row.device_id, row.customerMobile, row.share_msg)}>
                                                <MoreVertIcon />

                                            </IconButton>

                                        }
                                        title={row.id + " | SAR " + row.orderAmount}
                                        subheader={row.scheduleOrder}
                                    />
                                    <CardContent style={{ 'paddingTop': '0px' }} className={row.scheduleOrder && "schedule_order"} data-order_id={row.id} data-schedule_date={row.scheduleOrder}>
                                        <Typography id={"shared_id_" + row.id}>
                                            {row.share_time == 1 ? <span>SHARED</span> : <span style={{ color: '#ff0000' }}>NOT SHARED</span>}
                                        </Typography>
                                        <Typography variant="body2" component="p" style={{ "fontSize": "14px" }}>
                                            <b>{row.confirmed_by && "Confirmed: " + row.confirmed_by}</b> {row.confirmed_time && " | " + row.confirmed_time}
                                            <span id={"order_update_span_" + row.id} style={{ color: '#ff0000' }}>
                                                {
                                                    soldOutArray[row.id] == 'yes' ?
                                                        <span>
                                                            <br></br>
                                                            Order amended
                                                        </span> : null
                                                }
                                            </span>
                                        </Typography>
                                        <Typography className={classes.title}>
                                            {row.product_payment_type + " - "}
                                            {
                                                row.payment_status == 'paid' ?
                                                    <strong><span id={"paid_status_" + row.id} style={{ color: '#0a800e' }}>PAID </span></strong> :
                                                    <strong><span id={"paid_status_" + row.id} style={{ color: '#ff0000' }}>UNPAID </span></strong>
                                            }
                                            {row.is_plate == 'yes' && <strong style={{ color: '#ff0000' }}>| Is Plate: </strong>}
                                            {
                                                row.totalDistance ?
                                                    row.totalDistance >= 20 ?
                                                        <span style={{ color: '#ff0000' }}> | KM: {row.totalDistance}</span>
                                                        : <span>| KM: {row.totalDistance}</span>
                                                    : null
                                            }
                                        </Typography>
                                        <Typography className={classes.title} gutterBottom style={{ "fontSize": "12px" }}>
                                            {row.branchName} | <a target="_blank" href={"https://api.whatsapp.com/send?phone=" + row.shop_phone}>{" | " + row.shop_phone}</a>
                                            <br></br>
                                            {row.shiftOneText}
                                            <br></br>
                                            {row.shiftTwoText}
                                        </Typography>                                        
                                        <Typography variant="body2" component="p" style={{ "fontSize": "14px" }}>
                                            {row.location}
                                            {
                                                row.address_description &&
                                                <>
                                                <br></br>
                                                Location Name: {row.address_description}
                                                </>                                                                                            
                                            }
                                        </Typography>
                                        <Typography className={classes.title} gutterBottom style={{ "fontSize": "12px" }}>
                                            Customer No: <a target="_blank" href={"https://api.whatsapp.com/send?phone=" + row.customerMobile}>{row.customerMobile}</a> | {row.customerEmail}
                                        </Typography>

                                        {row.deliveryName &&
                                            <Typography className={classes.title} gutterBottom style={{ "fontSize": "12px" }}>
                                                {row.deliveryName && row.deliveryName}
                                                {row.deliveryName && <a target="_blank" href={"https://api.whatsapp.com/send?phone=" + row.deliveryNumber}>{" | " + row.deliveryNumber}</a>}
                                                {row.deliveryName && <span style={{ color: '#ff0000' }}> | Total order has: {row.has_order}</span>}
                                            </Typography>
                                        }

                                        <Typography className={classes.title} gutterBottom style={{ color: '#ff0000', "fontSize": "12px" }}>
                                            {
                                                row.sameCustomer && "Same Customer: " + row.sameCustomer
                                            }
                                        </Typography>
                                        <Typography className={classes.title} gutterBottom style={{ color: '#ff0000', "fontSize": "12px" }}>
                                            {
                                                row.sameBranch && "Same Branch: " + row.sameBranch
                                            }
                                        </Typography>

                                        <Typography style={{ color: '#ff0000', 'paddingTop': '0px', "fontSize": "14px" }} className="stepInterval" data-step_order_id={row.id} data-order_step_date={row.orderStepDate}>
                                            Timers: <span id={"step_order_id_" + row.id}>{row.orderStepDate}</span>
                                        </Typography>

                                        <Typography style={{ "fontSize": "12px" }}>
                                            {row.deliveryActualCharge && "Actual Delivery Cost: SAR " + row.deliveryActualCharge}
                                        </Typography>

                                        <Typography className={classes.title} gutterBottom style={{ color: '#ff0000' }}>
                                            {row.special_request_note && "Customer Note:" + row.special_request_note}
                                        </Typography>
                                        <Typography className={classes.title} gutterBottom style={{ color: '#ff0000' }}>
                                            {row.product_note && "Product Note:" + row.product_note}
                                        </Typography>


                                    </CardContent>
                                </Card>

                            ))
                    }

                </div>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Grids);
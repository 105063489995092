var url = window.location.href;
var urlData = url.split("/");
var domain = urlData[2];
var live_domain = '';
var socket_point = '';
if (domain == 'localhost:3000') {
    live_domain = 'http://localhost/server-b2b/backend/';
    socket_point = 'ws://dev.lavenderlogistic.com:9001';
    //live_domain = 'http://dev.lavenderlogistic.com/backend/';
    //live_domain = 'https://lavenderlogistic.com/backend/';
} else if (domain == 'admin.lavenderlogistic.com') {
     live_domain = 'https://lavenderlogistic.com/backend/';
    socket_point = 'wss://lavenderlogistic.com/wss2';
} else {
    live_domain = 'https://lavenderlogistic.com/backend/';
    socket_point = 'wss://lavenderlogistic.com/wss2';
}

export const LIVE_DOMAIN = live_domain;
export const SOCKET_URL = socket_point;
const API_ENDPOINT = LIVE_DOMAIN + "api/admin/";

export const RUNNING_PAGE_API_URL = API_ENDPOINT + 'RunningPage/running_list';
export const WHATSAPP_SHARE_API_URL = API_ENDPOINT + 'RunningPage/whatsappShareCount';